body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -webkit-overflow-scrolling: auto;
  -moz-osx-font-smoothing: grayscale;
}
 /*width */
::-webkit-scrollbar {
  width: 4px;
}

 /*Track */
::-webkit-scrollbar-track {
  background: #f1f1f1; 
  border-radius: 2px;
}
 
 /*Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 2px; 
}

 /*Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555; 
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.disable-dbl-tap-zoom {
  touch-action: manipulation;
}

@keyframes revolving-shadow {
    0% { box-shadow: 7px 0 7px rgba(63, 81, 181, 0.8); }
    12.5% { box-shadow: 5.5px 5.5px 7px rgba(63, 81, 181, 0.8); }
    25% { box-shadow: 0 7px 7px rgba(63, 81, 181, 0.8); }
    37.5% { box-shadow: -5.5px 5.5px 7px rgba(63, 81, 181, 0.8); }
    50% { box-shadow: -7px 0 7px rgba(63, 81, 181, 0.8); }
    62.5% { box-shadow: -5.5px -5.5px 7px rgba(63, 81, 181, 0.8); }
    75% { box-shadow: 0 -7px 7px rgba(63, 81, 181, 0.8); }
    87.5% { box-shadow: 5.5px -5.5px 7px rgba(63, 81, 181, 0.8); }
    100% { box-shadow: 7px 0 7px rgba(63, 81, 181, 0.8); }
}



.processing-element {
  /*width: 100px;*/
  /*height: 100px;*/
  border: 1px solid #000;
  animation: revolving-shadow 1s infinite linear; /* 2s duration, infinite loop, linear timing function */
}